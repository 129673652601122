import React, { Suspense } from 'react';
import './App.css';
import Layout from './components/layout';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { LoadingProvider } from './components/LoadingContext';
import { useParams } from 'react-router-dom';
import { LanguageProvider } from '../src/components/LanguageContext';

const Home = React.lazy(() => import('./pages/home/home'));
const AboutUs = React.lazy(() => import('./pages/about/about'));
const NotFound = React.lazy(() => import('./pages/not-found'));
const ServiceDetail = React.lazy(() => import('./pages/services/service-detail'));
const ScheduleDemo = React.lazy(() => import('./pages/schedule-demo'));
const BecomePartner = React.lazy(() => import('./pages/partners/become-partner'));
const OurPeople = React.lazy(() => import('./pages/about/our-team'));
const GetinTouch = React.lazy(() => import('./pages/contact-us/get-in-touch'));
const PrivacyPolicy = React.lazy(() => import('./pages/privacy-policy/privacy-policy'));
const TermsConditions = React.lazy(() => import('./pages/terms-conditions/terms-conditions'));
const Blog = React.lazy(() => import('./pages/resources/blog/blog'));
const BlogCategory = React.lazy(() => import('./pages/resources/blog/blog-category'));
const BlogDetail = React.lazy(() => import('./pages/resources/blog/blog-detail/blog-detail'));
const Career = React.lazy(() => import('./pages/about/career'));
const ThePlatform = React.lazy(() => import('./pages/platform/platform'));
const TheEdge = React.lazy(() => import('./pages/platform/edge'));
const CommonPage = React.lazy(() => import('./pages/common-page/common-page'));
const CookiePolicy = React.lazy(() => import('./pages/privacy-policy/cookie-policy'));
const TermsofUse = React.lazy(() => import('./pages/privacy-policy/terms-use'));
const CareerDetail = React.lazy(() => import('./pages/about/career-detail'));

function App() {

  const RedirectService = () => {
    const { slug } = useParams();
    const redirectUrl = `/en/service/${slug}`;

    return <Navigate to={redirectUrl} replace />;
  };
  const RedirectResources = () => {
    const { slug } = useParams();
    const redirectUrl = `/en/resources/${slug}`;

    return <Navigate to={redirectUrl} replace />;
  };
  const RedirectCategories = () => {
    const { slug } = useParams();
    const redirectUrl = `/en/category/${slug}`;

    return <Navigate to={redirectUrl} replace />;
  };


  return (
    <LanguageProvider>
        <Router>
          <Routes>
            <Route element={
              <Suspense >
                <LoadingProvider>
                  <Layout />
                </LoadingProvider>
              </Suspense>
            }
            >
              <Route path="/" element={<Home />} />
              <Route path="/:langCode" element={<Home />} />
              <Route path="/:langCode/company/company-page" element={<AboutUs />} />
              <Route path="/:langCode/service/:slug" element={<ServiceDetail />} />
              <Route path="/:langCode/schedule-demo" element={<ScheduleDemo />} />
              <Route path="/:langCode/become-partner" element={<BecomePartner />} />
              <Route path="/:langCode/company/our-people" element={<OurPeople />} />
              <Route path="/:langCode/company/get-in-touch" element={<GetinTouch />} />
              <Route path="/:langCode/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/:langCode/cookie-policy" element={<CookiePolicy />} />
              <Route path="/:langCode/terms-of-use" element={<TermsofUse />} />
              <Route path="/:langCode/terms-condition" element={<TermsConditions />} />
              <Route path="/:langCode/resources" element={<Blog />} />
              <Route path="/:langCode/resources/:slug" element={<BlogDetail />} />
              <Route path="/:langCode/category/:slug" element={<BlogCategory />} />
              <Route path="/:langCode/company/careers" element={<Career />} />
              <Route path=":langCode/company/:slug" element={<CareerDetail />} />
              <Route path="/:langCode/platform/the-platform" element={<ThePlatform />} />
              <Route path="/:langCode/platform/the-edge" element={<TheEdge />} />
              <Route path="*" element={<NotFound />} />
              <Route path="/company/company-page" element={<Navigate to="/en/company/company-page" replace />} />
              <Route path="/service/:slug" element={<RedirectService />} />
              <Route path="/schedule-demo" element={<Navigate to="/en/schedule-demo" replace />} />
              <Route path="/become-partner" element={<Navigate to="/en/become-partner" replace />} />
              <Route path="/company/our-people" element={<Navigate to="/en/company/our-people" replace />} />
              <Route path="/company/get-in-touch" element={<Navigate to="/en/company/get-in-touch" replace />} />
              <Route path="/privacy-policy" element={<Navigate to="/en/resources" replace />} />
              <Route path="/terms-condition" element={<Navigate to="/en/terms-condition" replace />} />
              <Route path="/resources" element={<Navigate to="/en/resources" replace />} />
              <Route path="/resources/:slug" element={<RedirectResources />} />
              <Route path="/category/:slug" element={<RedirectCategories />} />
              <Route path="/company/careers" element={<Navigate to="/en/company/careers" replace />} />
              <Route path="/platform/the-platform" element={<Navigate to="/en/platform/the-platform" replace />} />
              <Route path="/platform/the-edge" element={<Navigate to="/en/platform/the-edge" replace />} />
              <Route path="/common-page" element={<CommonPage />} />




            </Route>
          </Routes>
        </Router>
    </LanguageProvider>
  );
}
// const RedirectToResources = () => {
//   const { slug } = useParams();
//   return slug ? <Navigate to={`/resources/${slug}`} replace /> : null;
// };
export default App;
